type Translation = {
  lang_iso_code: 'fr' | 'en' | 'pt';
  label: string;
  description: string;
};

export function getLocaleTranslation(translations: Translation[]) {
  const { $i18n } = useNuxtApp();
  return translations?.find((t) => t.lang_iso_code === $i18n.locale.value);
}
